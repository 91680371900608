import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, map, Observable} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LoginPayload, LoginResponse } from '../models/auth.model';
import { environment } from '../../environments/environment';
import { isPlatformBrowser } from '@angular/common';
import Swal from 'sweetalert2';
import { CommonResponse } from '../models/common.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  deviceTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('test');
  private menuState = new BehaviorSubject<boolean>(false);
  profileDetails: BehaviorSubject<LoginResponse | null> = new BehaviorSubject<LoginResponse | null>(
    null
  );
  resetTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  menuState$ = this.menuState.asObservable();
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject.asObservable();

  constructor(
    private http: HttpClient,
    private _translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: object
  ) {}

  isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      
      if (
        localStorage.getItem(environment.storageKey) &&
        localStorage.getItem(environment.storageKey) !== null
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  isWebUserAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      
      if (
        localStorage.getItem(environment.storageKeyWeb) &&
        localStorage.getItem(environment.storageKeyWeb) !== null
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  loginUser(loginDetails: LoginPayload): Observable<LoginResponse> {
    return this.http
      .post<{
        data: LoginResponse;
      }>(`${environment.base_url}admin/login`, loginDetails)
      .pipe(map(res => res.data));
  }

  logout(): Observable<CommonResponse> {
    return this.http.get<CommonResponse>(`${environment.base_url}admin/logout`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logoutAlert(): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return new Promise((resolve: any) => {
      Swal.fire({
        title: this._translateService.instant('AreYouSure'),
        text: this._translateService.instant('Youwillbeloggedout'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this._translateService.instant('Cancel'),
        confirmButtonColor: '#ffaf18',
        cancelButtonColor: '#000',
        confirmButtonText: this._translateService.instant('Logout')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }).then((result: any) => {
        return resolve(result);
      });
    });
  }
}